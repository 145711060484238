<template>
  <div class="personal main-content" :class="open ? 'open' : ''">
    <h2 class="cart-title">
      <div class="_ico">
        <img src="@/assets/image/personal/title.png" alt="Member center" />
      </div>
      <span>&nbsp;</span>
    </h2>
    <div class="personal-content">
      <div class="personal-nav">
        <div class="personal-box">
          <ul>
            <li :class="steps == 1 ? 'active' : ''" @click="tabClick(1)">
              個人資訊
            </li>
            <li :class="steps == 2 ? 'active' : ''" @click="tabClick(2)">
              我的訂單
            </li>
            <!--            <li :class="steps == 3 ? 'active' : ''" @click="tabClick(3)">eSIM Coin紀錄</li>-->
            <li
              v-if="false"
              :class="steps == 4 ? 'active' : ''"
              @click="tabClick(4)"
            >
              我的收藏
            </li>
          </ul>
          <div class="signOut">
            <button class="btn btn-danger" @click="Logout">登出</button>
          </div>
        </div>
      </div>
      <div class="personal-body">
        <div class="body-box" v-if="steps == 1">
          <div class="body-title">個人資訊</div>
          <div class="user-box">
            <div class="row">
              <div class="col user_img">
                <div class="user-item">
                  <label class="user-img">
                    <input type="file" class="hide" @change="headChange" />
                    <div class="_img">
                      <!--                      <img :src="headImg" />-->
                      <el-image :src="headImg">
                        <div slot="error" class="image-slot">
                          <img
                            src="@/assets/image/header/headImg.png"
                            alt="profile"
                          />
                        </div>
                      </el-image>
                    </div>
                    <div class="_text">修改個人資料</div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">電子郵件</div>
                    <div class="_inner">
                      <el-input
                        v-model="$store.state.userInfo.account"
                        placeholder=""
                        disabled
                      ></el-input>
                    </div>
                  </div>
                </div>
                <div class="user-item" v-if="false">
                  <div class="input-box">
                    <div class="_label">註冊方式</div>
                    <div class="_inner">
                      <el-input
                        v-model="zucefangshi"
                        placeholder=""
                        disabled
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">修改密碼</div>
                    <div class="_inner">
                      <el-input v-model="password" placeholder=""></el-input>
                    </div>
                  </div>
                </div>
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">確認密碼</div>
                    <div class="_inner">
                      <el-input
                        v-model="confirm_pass"
                        placeholder=""
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="user-item">
                <div class="input-box">
                  <div class="_label">暱稱</div>
                  <div class="_inner">
                    <el-input
                      v-model="$store.state.userInfo.nick_name"
                      placeholder=""
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="user-footer">
              <button class="btn btn-primary" @click="updateuser">
                確認修改
              </button>
            </div>
          </div>
        </div>
        <div class="order-box2" v-if="steps == 2">
          <div class="body-title">我的訂單</div>
          <div class="order-nav">
            <ul>
              <li :class="state == 0 ? 'active' : ''" @click="clickState(0)">
                所有訂單
              </li>
              <li :class="state == 1 ? 'active' : ''" @click="clickState(1)">
                繼續付款
              </li>
              <li :class="state == 2 ? 'active' : ''" @click="clickState(2)">
                待出貨
              </li>
              <li :class="state == 3 ? 'active' : ''" @click="clickState(3)">
                訂單完成
              </li>
              <li :class="state == 5 ? 'active' : ''" @click="clickState(5)">
                已取消
              </li>
              <li :class="state == 6 ? 'active' : ''" @click="clickState(6)">
                已退款
              </li>
            </ul>
          </div>
          <div class="order-box">
            <div class="order-head">
              <div class="row">
                <div class="col _merge">
                  <div class="row">
                    <div class="col _commodity">方案內容</div>
                    <div class="col __merge">
                      <div class="row">
                        <div class="col _quantity">數量</div>
                        <div class="col _unitPrice">單價</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col _discount">折扣</div>
                <div class="col _money">金額</div>
                <div class="col _state">訂單狀態</div>
              </div>
            </div>
            <div class="order-body">
              <div
                class="order-list"
                v-for="(item, i) in filterOrderList"
                :key="i"
              >
                <div class="_head">
                  <span>訂單編號：{{ item.order_number }}</span>
                  <span class="_time">訂購時間：{{ item.addTime }}</span>
                  <span
                    class="_cancel"
                    v-if="item.status == 1"
                    @click="CancelOrder(item.id)"
                    >取消訂單</span
                  >
                </div>
                <div class="_body">
                  <div class="row">
                    <div class="col _merge">
                      <div
                        class="_list"
                        v-for="(iten, index) in item.plan_list"
                        :key="index + 1"
                      >
                        <div class="row">
                          <div class="col _commodity">
                            <div class="_info">
                              <div class="_img"><img :src="iten.image" /></div>
                              <div class="_txt">
                                <div class="_name">{{ iten.name }}</div>
                                <!--                                <div class="_describe">{{ iten.sketch }}</div>-->
                                <div class="_describe">
                                  {{ iten.days + " Days" }} {{ iten.spec
                                  }}<small
                                    v-if="
                                      iten.package &&
                                      iten.package.includes('By Day')
                                    "
                                    >/ 天</small
                                  ><span style="display: inline-block">
                                    x {{ iten.num }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col __merge">
                            <div class="row">
                              <div class="col _quantity" data-name="數量:">
                                <div class="_txt">{{ iten.num }}</div>
                              </div>
                              <div
                                class="col _unitPrice"
                                data-name="Unit price:"
                              >
                                <div class="_txt">
                                  $NT{{
                                    iten.special_amount &&
                                    iten.special_amount.toLocaleString()
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col _discount">
                      <div class="_txt">
                        $NT{{
                          item.discount
                            ? item.discount && item.discount.toLocaleString()
                            : 0
                        }}
                      </div>
                    </div>
                    <div class="col _money" data-name="金額:">
                      <div class="_txt">
                        $NT{{
                          item.discount_amount &&
                          item.discount_amount.toLocaleString()
                        }}
                      </div>
                    </div>
                    <div class="col _state">
                      <div class="_txt">
                        <span
                          v-if="item.status == 3"
                          class="_ico"
                          @click="opendig(item)"
                        ></span>
                        <span
                          v-if="item.status != 4"
                          @click="item.status == 1 ? goTO(item) : ''"
                          :style="{
                            background:
                              item.status == 1
                                ? '#0B57C9'
                                : item.status == 2 || item.status == 7
                                ? '#D49C07'
                                : item.status == 3
                                ? '#219D71'
                                : item.status == 5 || item.status == 4
                                ? '#6C757D'
                                : '#F64343',
                          }"
                          class="_btn"
                          >{{
                            item.status == 1
                              ? "繼續付款"
                              : item.status == 2
                              ? "待出貨"
                              : item.status == 3
                              ? "訂單完成"
                              : item.status == 4
                              ? ""
                              : item.status == 5
                              ? "已取消"
                              : item.status == 6
                              ? "已退款"
                              : "待出貨"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body-box" v-if="steps == 3">
          <div class="body-title">eSIM Coin紀錄</div>
          <div class="user-box pointens">
            <points></points>
          </div>
        </div>
        <div class="body-box" v-if="steps == 4">
          <div class="body-title">我的收藏</div>
          <div class="user-box"></div>
        </div>
      </div>
    </div>

    <el-dialog
      title="訂單資訊"
      :visible.sync="dialogTableVisible"
      style="text-align: center"
      :close-on-click-modal="false"
    >
      <div style="max-height: 65vh; display: flex; flex-direction: column">
        <div class="_title">
          <span style="color: red; letter-spacing: 0; padding-right: 5px"
            >點擊查看</span
          >
          <router-link to="/works?name=herf">安裝教學</router-link>
        </div>
        <el-table height="100%" :data="palanList">
          <el-table-column prop="schemeName" label="方案名稱" />
          <el-table-column prop="snPin" label="兌換碼/掛號號碼">
          </el-table-column>
          <el-table-column prop="qrcode_url" label="查詢/兌換" width="100px">
            <template v-slot="{ row }">
              <div v-if="row.card_type != 2 && !row.statusOperate">
                <span
                  v-if="!row.qrcode"
                  class="exchange"
                  @click="exchange(row)"
                >
                  兌換
                </span>
                <span
                  v-else
                  class="btn_ico"
                  @click="
                    qrCodeVisible = true;
                    seeQrCode = row.qrcode_url;
                  "
                ></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <h3 v-if="obj.have_esim_type > 1" style="margin-bottom: 10px">出貨單號：{{ obj.delivery_num }}</h3>
      <div class="qrcodeList" v-for="(item,i) in palanList" :key="i">
        <h4>商品名稱:{{ item.name }}</h4>
        <div  style="display: flex;gap: 10px;justify-content: center">
        <img v-for="(item,i) in JSON.parse(item.qrcode_url)" :key="i" :src="item" alt="item.name" width="100px" height="100px"/>
        </div>
      </div> -->
    </el-dialog>
    <el-dialog
      title="查看QR Code"
      :visible.sync="qrCodeVisible"
      style="text-align: center"
      :close-on-click-modal="false"
    >
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
        "
      >
        <img :src="seeQrCode" alt="QR Code" />
        <!-- <img v-for="item in seeQrCode" :key="item" :src="item" alt="qrCode"> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import QRCode from 'qrcodejs2'
import {
  updateuserInformation,
  getOrderList,
  CancelOrder,
  userInformation,
  uploadHead,
  login,
} from "@/request/api";

export default {
  name: "personal",
  components: {
    points: () => import("@/views/Personal/points.vue"),
  },
  data() {
    return {
      open: false,
      search: "",
      steps: 1, // 1.個人資料 2.我的訂單 3.我的訂單 4.我的收藏
      state: 0, // 1 已下單（繼續支付）  2已付款  3已發貨   4取消中   5已取消 6已退款
      email: "",
      imgInitial: require("@/assets/image/personal/user.png"),
      headImg: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).user_head_show
        : require("@/assets/image/header/headImg.png"),
      userinfo: JSON.parse(window.localStorage.getItem("userInfo")),
      zucefangshi: "一般注冊",
      password: "",
      confirm_pass: "",
      orderList: [],
      headImgTwo: "",
      dialogTableVisible: false,
      qrcode: "",
      palanList: [],
      obj: {},
      qrCodeVisible: false,
      seeQrCode: "",
    };
  },
  computed: {
    filterOrderList() {
      if (this.state !== 0) {
        if (this.state == 2) {
          return this.orderList.filter(
            (v) => v.status === String(this.state) || v.status === "7"
          );
        } else {
          return this.orderList.filter((v) => v.status === String(this.state));
        }
      }
      return this.orderList;
    },
  },
  created() {
    this.steps = this.$route.query.type || 1;
    window.addEventListener("popstate", this.GoReturn, false);
    if (this.steps == 2) {
      this.getOrderList();
    }
  },
  methods: {
    opendig(row) {
      console.log(row, "++====");
      this.obj = row;
      const palanList = [];
      if (row.redemption_code && row.redemption_code.length) {
        row.redemption_code.forEach((item) => {
          // let schemeNames = item.product_name.split(", ")
          let schemeName = `${item.name} ${item.days}天 （${item.spec}）`;
          palanList.push({
            ...item,
            schemeName,
          });
        });
      } else {
        row.plan_list.forEach((item) => {
          // let schemeNames = item.product_name.split(", ")
          let schemeName = `${item.name} ${item.days}天 （${item.spec}）`;
          if (item.snPin && item.snPin.length) {
            item.snPin &&
              JSON.parse(item.snPin).forEach((val) => {
                palanList.push({
                  snPin: val,
                  statusOperate: true,
                  schemeName,
                });
              });
          }
        });
      }
      this.palanList = palanList;
      this.dialogTableVisible = true;
    },
    async goTO(item) {
      let list = [];
      item.plan_list.forEach((it) => {
        let obj = {
          id: it.esimart_plan_id,
          price_id: it.esimart_price_id,
          num: it.num,
        };
        list.push(obj);
      });

      let data = {
        plan: list,
        promo_code: "",
        remark: "",
        step: 1,
      };
      this.$router.push({
        path: "closing",
        query: { data: JSON.stringify(data) },
      });
      // let data = {
      //   order_id: item.id
      // }
      // let res = await toPay(data);
      // if (res.code == 200) {
      //   window.location = res.data.pay_url
      // } else {
      //   this.$message.warning(res.msg)
      // }
    },
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: "/details",
        query: {
          id: item.title,
        },
      });
    },
    // 加入购物车
    toShop(item) {
      // console.log(2222)
    },
    // 加入我喜欢
    toLove(item) {},
    GoReturn() {
      this.open = false;
    },
    tabClick(item) {
      this.open = true;
      this.steps = item;
      if (item == 2) {
        this.getOrderList();
      }
      this.$router.push({
        path: "/personal",
        query: {
          type: item,
        },
      });
    },
    clickState(item) {
      this.state = item;
    },
    headChange(e) {
      this.get_object_url(e.target.files[0]);
    },
    async get_object_url(file) {
      var url = null;
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      let res = await uploadHead(fileFormData);
      if (res.code == 200) {
        let user = JSON.parse(window.localStorage.getItem("userInfo"));

        this.headImg = res.data.show_url;
        this.headImgTwo = res.data.save_url;
      } else {
        this.$message({
          type: "warning",
          message: "Upload failed",
        });
      }
    },

    //登出
    Logout() {
      window.localStorage.removeItem("TOKEN_NAME");
      window.localStorage.removeItem("userInfo");
      let status = this.$store.state.loginStatus; // 重新赋值
      this.$store.commit("changeStatus", false);
      this.$nextTick(() => {
        this.$store.commit("changeuserInfo", JSON.stringify({}));
        this.$store.commit("changgetCart");
      });
      this.$router.push("/");
    },

    //修改用戶信息
    async updateuser() {
      // console.log(this.headImgTwo);return;
      let req = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if (this.password || this.confirm_pass) {
        if (req.test(this.password) && this.password == this.confirm_pass) {
          let data = {
            nick_name: this.$store.state.userInfo.nick_name,
            password: this.password,
            confirm_pass: this.confirm_pass,
            user_head: this.headImgTwo,
          };
          let res = await updateuserInformation(data);
          if (res.code == 1) {
            this.login();
          }
        } else if (!req.test(this.password)) {
          this.$message({
            type: "warning",
            message:
              "Password length is 6-20 characters of alphanumeric combination.",
          });
        } else if (this.passWord != this.confirm_pass) {
          this.$message({
            type: "warning",
            message:
              "The passwords entered twice do not match, please re-enter.",
          });
        }
      } else {
        let data = {
          nick_name: this.$store.state.userInfo.nick_name,
          user_head: this.headImgTwo,
        };
        let res = await updateuserInformation(data);
        if (res.code == 1) {
          this.getUserxinxi();
        }
      }
    },

    async login() {
      let data = {
        account: this.$store.state.userInfo.account,
        password: this.password,
      };
      let rep = await login(data);
      if (rep.code == 1) {
        window.localStorage.setItem(
          "TOKEN_NAME",
          JSON.stringify(rep.data.token)
        );
        this.password = "";
        this.confirm_pass = "";
        this.getUserxinxi();
        this.$emit("close", false);
        this.$nextTick(() => {
          this.$store.commit("changeStatus", true);
          this.$store.commit("changgetCart");
        });
        this.$router.push({
          path: "/personal",
          query: {},
        });
      } else {
        this.$message({
          type: "warning",
          message: rep.msg,
        });
      }
    },

    async getUserxinxi() {
      let res = await userInformation();
      window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
      this.$nextTick(() => {
        this.$store.commit("changeuserInfo", JSON.stringify(res.data.user));
      });
    },

    //獲取訂單
    async getOrderList() {
      let res = await getOrderList();
      if (res.code == 1) {
        this.orderList = res.data;
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    },

    //取消訂單
    async CancelOrder(id) {
      let data = {
        order_id: id,
      };
      let res = await CancelOrder(data);
      if (res.code == 1) {
        this.getOrderList();
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    },
    // 兌換
    exchange(row) {
      this.$router.push({
        path: "/exchange",
        query: {
          code: row.snPin,
          email: row.email,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
._title {
  margin-top: -20px !important;
  margin-bottom: 20px;

  a {
    color: #00a0e9 !important;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #2e2e2e;
  border-color: #2e2e2e;
  color: #ffffff;
}

.qrcodeList {
  margin-bottom: 20px;

  h4 {
    margin: 0;
    margin-bottom: 10px;
  }
}

.pointens {
  background-color: #fafafa;
  margin-top: 30px;
  padding: 10px;
}

.exchange {
  display: inline-block;
  padding: 0px 10px;
  border-radius: 20px;
  background-color: rgba(164, 173, 179, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
}

.btn_ico {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGVSURBVHgBxVeLkYMgEH1JBXYgHcQOtIO7DijBEijBEijBuwq8DrQD7SB3FdyBYkKIwJIzkzfzMkyAfbuyy+cAGjLFk+K7YqXIzH8a34qD4ZfiJ3aANi4Uz4q/RI5mTo4HUScKbjnAkYjmH4IuG6qo3FF0pYyJ7hkpOXJONNArdoapOVC7ogxLMvgmaAGBawnZqBRbovDZtSEQzs4ccQiiuLAnjYmimUeckiOXqN8Cg7hjuHaclBuOjQTxMuTl6BgUgXGZ41xMeM7wztPZWsaySCTCGssIwv1R/RTYxmC1T8agD5XVnhAH08IZYeAP9kV2DHQyqz1hOf58+LDaBYjw7T5usfuSxk0uDlpJzVsgJWlWo701ucH9UlGyugPCRa+N50iHAKGcysig8QnietOaPxXllJEeB7Tjraev8wRC8u6u+I3B3nHY/TI+mzd5Q406xlVcIGHZKNlIYSgADg9ecvVZIZ8gKkHES663Kzhoh3porWs8CIYlQ1McCF0MLziADv1gK7GcPgVuH20Trg+2AeGTbMYf2T754a6t9KAAAAAASUVORK5CYII=);
  background-size: 100%;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 800px) {
  ::v-deep .el-dialog {
    width: 100%;
  }

  .pointens {
    background: transparent !important;
    padding: 0;
  }

  .personal .personal-body {
    padding: 0;
  }

  .order-body .order-list ._body .col._state {
    min-width: 100%;
    position: static;
    margin-bottom: 5px;
    padding: 0px 15px;

    ._ico {
      top: 8px;
    }

    /* position: relative; */
  }
}
</style>
